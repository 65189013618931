import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'App',
    component: () => import('../Home.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('../Detail.vue'),
    meta: {
      title: '文章详情'
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
];
const createRouter = () =>
  new VueRouter({
    mode: 'history',
    routes
  })

const router = createRouter()

export default router
